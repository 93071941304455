import axios from 'axios';

//const urlApi = "https://dev.rbodev.fr/api/";
const urlApi = "https://www.rbodev.fr/api/";

class AuthService {
  login(user) {
    return axios
      .post(urlApi + "login", {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      }
    );
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('userData');
    localStorage.removeItem('member');
    localStorage.removeItem('members');
    localStorage.removeItem('membersForm');
    localStorage.removeItem('memberSearch');
    localStorage.removeItem('group');
    localStorage.removeItem('groups');
    localStorage.removeItem('userGroups');
    localStorage.removeItem('rems');
    localStorage.removeItem('mires');
    localStorage.removeItem('mercicas');
  }
}

export default new AuthService();
